/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../src/style/abstract/variables';
@import '../../../../src/style/abstract/media';
@import '../../../../src/style/abstract/button';
@import '../../../../src/style/abstract/loader';
@import '../../../../src/style/abstract/icons';
@import '../../../../src/style/abstract/parts';

.MyAccountOverlay-OtpLogin {
    &-Or {
        text-align: center;
        &Text {
            display: inline-block;
            width: 30px;
            text-align: center;
            background: #f6f7f8;
        }
        &Line{
            width: 100px;
            height: 1px;
            background: #71333f4f;
            position: absolute;
            left: 50%;
            top: 8px;
            transform: translateX(-50%);
        }
    }
    &-Link {
        margin-top: 10px;
        &:hover {
            cursor: pointer;
        }
    }
}